<template>
  <div
    class="exhibits"
    :class="{
      loading: !isProjectEntered,
      'song-playing': currentTrack !== null,
    }"
  >
    <div class="audio">
      <div v-show="isProjectEntered" class="album">
        <div class="inner">
          <div class="cover">
            <a
              :href="project.link"
              title="View album on Bandcamp"
              target="_blank"
            >
              <img
                :src="'https://nickjeffrey.com/files/mp3/' + project.largeImg"
              />
            </a>
          </div>
          <div class="playlist">
            <ol :class="{ playing }">
              <li
                v-for="(item, index) in tracks"
                :key="index"
                class="track"
                :class="{ active: currentTrack == index }"
                v-on:click="selectTrack(index)"
              >
                <div class="title">{{ item.name }}</div>
                <div class="duration">
                  <span v-if="currentTrack == index">{{ progress }}</span>
                  <span v-else>{{ item.duration }}</span>
                </div>
              </li>
            </ol>
            <p>
              Released {{ project.released }}. Available through
              <a :href="project.link" target="_blank">Bandcamp</a>.
            </p>
          </div>
        </div>
      </div>
      <div class="player">
        <vue-plyr ref="plyr" :options="playerOptions">
          <audio controls playsinline>
            <source
              src="http://nickjeffrey.com/files/mp3/residual-drifter---healer---01-nocturnal-ecologies.mp3"
              type="audio/mp3"
            />
          </audio>
        </vue-plyr>
      </div>
    </div>
    <BackButton class="close-button button" title="Close album" />
    <FullscreenButton v-if="fullscreenSupport && fullscreen !== 'global'" />
  </div>
</template>

<script>
import store from "@/store";
import { CLOSE_FULLSCREEN } from "@/store/handlers";
import { mapState, mapGetters } from "vuex";
import { controls } from "@/utils/controls";
import BackButton from "@/components/BackButton";
import FullscreenButton from "@/components/FullscreenButton";

export default {
  components: {
    BackButton,
    FullscreenButton,
  },
  data() {
    return {
      player: null,
      playerOptions: {
        controls: controls,
      },
      currentTime: 0,
      currentTrack: null,
      playing: false,
    };
  },
  computed: {
    ...mapState(["fullscreen", "fullscreenSupport", "project"]),
    ...mapGetters(["isProjectEntered"]),
    elapsed() {
      let time = new Date(this.currentTime * 1000).toISOString();
      return this.currentTime < 3600 ? time.substr(14, 5) : time.substr(11, 8);
    },
    progress() {
      return this.elapsed + " / " + this.tracks[this.currentTrack].duration;
    },
    tracks() {
      return this.project.tracks;
    },
  },
  methods: {
    prevTrack() {
      let id =
        this.currentTrack > 0 ? this.currentTrack - 1 : this.tracks.length - 1;
      this.selectTrack(id);
    },
    nextTrack() {
      let id =
        this.currentTrack < this.tracks.length - 1 ? this.currentTrack + 1 : 0;
      this.selectTrack(id);
    },
    selectTrack(id) {
      if (id !== this.currentTrack) {
        this.currentTrack = id;
        this.currentTime = 0;
        this.player.source = {
          type: "audio",
          title: "Example title",
          sources: [
            {
              src: `http://nickjeffrey.com/files/mp3/${this.tracks[id].file}.mp3`,
              type: "audio/mp3",
            },
          ],
        };
        this.player.play();
      } else {
        if (this.player.playing) {
          this.player.pause();
        } else if (this.player.paused) {
          this.player.play();
        }
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.player = this.$refs.plyr.player;
      this.player.on("ready", () => {
        document
          .getElementById("prevTrack")
          .addEventListener("click", this.prevTrack);
        document
          .getElementById("nextTrack")
          .addEventListener("click", this.nextTrack);
      });

      this.player.on("playing", () => {
        this.playing = true;
      });

      this.player.on("pause", () => {
        this.playing = false;
      });

      this.player.on("timeupdate", () => {
        this.currentTime = Math.trunc(this.player.currentTime);
      });

      this.player.on("ended", () => {
        if (this.currentTrack < this.tracks.length - 1) {
          this.selectTrack(this.currentTrack + 1);
        } else {
          this.currentTrack = null;
        }
      });
    });
  },
  beforeRouteLeave(to, from, next) {
    if (this.fullscreen === "local") {
      store.dispatch(CLOSE_FULLSCREEN).then(() => {
        let watcher = store.watch(
          function (state) {
            return state.fullscreen;
          },
          function () {
            watcher();
            next();
          }
        );
      });
    } else next();
  },
};
</script>

<style>
.audio {
  position: absolute;
  width: 100%;
  height: 100%;
  font-family: Graphik, "Helvetica Neue", Helvetica, sans-serif;
  font-weight: 300;
  display: flex;
  flex-flow: column nowrap;
  overflow: hidden;
}

.audio .album {
  flex-basis: 100%;
  overflow: auto;
}

.audio .album .inner {
  margin: 0 3rem;
  padding: 3.5rem 0;
}

.audio .album .cover {
  flex: 2 1 0;
  margin-top: 1.4rem;
}

.audio .album .cover img {
  max-width: 100%;
  border: 1px solid var(--primary-color);
}

.audio .player {
  display: none;
  border-top: 1px solid var(--primary-color);
}

.audio .player .plyr__control svg {
  fill: var(--primary-color);
}

.audio .player .plyr__control:hover svg {
  fill: var(--background-color);
}

.audio .player .plyr__time {
  color: var(--primary-color);
}

.audio .player .plyr__time--duration {
  display: none;
}

.song-playing .audio .player {
  display: block;
}

.song-playing .maximise-button {
  bottom: 5.75rem;
}

.audio .playlist {
  flex: 3 1 0;
  margin: 0 auto;
  padding-top: 2em;
  font-size: 14px;
}

.audio .playlist p {
  margin-top: 2.125em;
  padding: 1.0625em 0;
}

.audio .playlist ol {
  list-style-type: none;
}

.audio .playlist ol li {
  display: block;
  padding: 1.0625em 0;
  font-weight: 200;
  counter-increment: customlistcounter;
}

.audio .playlist ol li:before {
  content: counter(customlistcounter);
  float: left;
  width: 1em;
  margin-right: 1.25em;
}

.audio .playlist ol li:hover:before {
  content: "\f152";
  font-family: "themicons";
}

.audio .playlist ol li.active .title {
  font-weight: 400 !important;
}

.audio .playlist ol li.active:before {
  content: "\f152";
  font-family: "themicons";
}

.audio .playlist ol.playing li.active:before {
  content: "\f151";
  font-family: "themicons";
}

.audio .playlist ol:first-child {
  counter-reset: customlistcounter;
}

.audio .playlist ol li div {
  display: inline-block;
}

.audio .playlist ol li .title {
  max-width: 50%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.audio .playlist ol li .duration {
  float: right;
  padding-left: 21px;
  font-size: 12px;
  margin-top: 1px;
}

@media only screen and (min-width: 769px) {
  .audio .album .inner {
    display: flex;
    flex-wrap: nowrap;
    margin: 0 5rem;
    padding: 6rem 0;
  }

  .audio .playlist {
    padding-top: 0;
  }

  /*
  .audio .playlist {
    margin-left: 3rem;
  }*/

  .audio .playlist {
    margin-left: 3rem;
  }

  .audio .playlist ol li,
  .audio .playlist p {
    padding-left: 0.9375em;
  }

  .audio .player .plyr__time--duration {
    display: block;
  }
}

@media only screen and (min-width: 1025px) {
  .audio .album .inner {
    margin: 0 9.6rem;
  }

  .audio .playlist {
    margin-left: 5rem;
  }
}

/* Dark Theme Styles
================================================== */

#app.dark-theme .audio .player {
  border-top: 1px solid white;
}

#app.dark-theme .audio .player .plyr__control svg {
  fill: #ffffff;
}
</style>
